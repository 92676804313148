(function() {
    'use strict';

    angular.module('uabPromiseManagement', [
        'ionicAlert',
        'ionicRequest',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('uabPromiseManagement').service(
        'PromiseManagementService',
        PromiseManagementService
    );

    PromiseManagementService.$inject = [
        'DefaultVariableService',
        'ErrorService',
        'RequestManagementService',
        '$q'
    ];

    function PromiseManagementService(
        DefaultVariableService,
        ErrorService,
        RequestManagementService,
        $q
    ) {
        var PromiseManagementService = this;

        var ACTION_GET = 'get';
        var ACTION_RECOMMENDED = 'recommended';

        PromiseManagementService.addRequest = addRequest;
        function addRequest(options, promise) {
            var action = RequestManagementService.getAction(options);

            if (action === ACTION_GET || action === ACTION_RECOMMENDED) {
                var key = PromiseManagementService.getKey(options);
                if (!PromiseManagementService.hasRequest(options)) {
                    PromiseManagementService.requests[key] = promise.then(
                        function(results) {
                            PromiseManagementService.removeRequest(options);

                            return results;
                        }
                    );
                }

                return PromiseManagementService.getRequest(options);
            }

            return promise;
        }

        PromiseManagementService.combinePromises = combinePromises;
        function combinePromises(promises) {
            return $q.all(promises);
        }

        PromiseManagementService.convertPromise = convertPromise;
        function convertPromise(promise) {
            return $q.resolve(promise);
        }

        PromiseManagementService.generateError = generateError;
        function generateError(errorMessage, response) {
            if (errorMessage) {
                ErrorService.addError(errorMessage);
            }

            response = DefaultVariableService.get(
                response,
                false
            );

            var deferred = $q.defer();
            deferred.reject(response);
            return deferred.promise;
        }

        PromiseManagementService.generateSuccess = generateSuccess;
        function generateSuccess(response) {
            var deferred = $q.defer();
            deferred.resolve(response);
            return deferred.promise;
        }

        PromiseManagementService.getKey = getKey;
        function getKey(options, exclude) {
            exclude = DefaultVariableService.getString(exclude);

            var parts = {
                'action':    RequestManagementService.getAction(options),
                'id':        RequestManagementService.getId(options),
                'join':      RequestManagementService.getJoin(options),
                'limit':     RequestManagementService.getLimit(options),
                'model':     RequestManagementService.getModel(options),
                'order':     RequestManagementService.getOrder(options),
                'page':      RequestManagementService.getPage(options),
                'params':    RequestManagementService.getParams(options),
                'subaction': RequestManagementService.getSubAction(options),
            };

            var key = '';
            for (var part in parts) {
                if (parts.hasOwnProperty(part)) {
                    if (exclude.indexOf(part) === -1) {
                        key += parts[part];
                    }
                }
            }

            return key;
        }

        PromiseManagementService.getRequest = getRequest;
        function getRequest(options) {
            var key = PromiseManagementService.getKey(options);

            return PromiseManagementService.requests[key];
        }

        PromiseManagementService.hasRequest = hasRequest;
        function hasRequest(options) {
            var key = PromiseManagementService.getKey(options);

            return PromiseManagementService.requests.hasOwnProperty(key);
        }

        PromiseManagementService.removeRequest = removeRequest;
        function removeRequest(options) {
            var key = PromiseManagementService.getKey(options);

            delete PromiseManagementService.requests[key];
        }

        PromiseManagementService.reset = reset;
        function reset() {
            PromiseManagementService.requests = {};
        }

        PromiseManagementService.reset();

        return PromiseManagementService;
    }
})();
